import React, { useCallback, useEffect, useState } from 'react';
import { Header } from 'antd/es/layout/layout';
import { Avatar, Dropdown, MenuProps, message } from 'antd';
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { logout, setUser } from '../../redux/auth/authSlice';
import { getProfile } from '../../services/authenServices';
import { User } from '../../redux/auth/type';
import LoadingComponent from '../../components/LoadingComponent';
import { ERROR_MESSAGE } from '../../constants/common';

const HeaderComponent: React.FC = () => {
  const { user, isAuthenticated } = useSelector(
    (state: RootState) => state.auth
  );

  const [fetchingUser, setFetchingUser] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const appLogout = useCallback(() => {
    dispatch(logout());
    navigate('/login');
  }, [dispatch, navigate]);

  useEffect(() => {
    if (isAuthenticated && !user) {
      const fetchUser = async () => {
        try {
          setFetchingUser(true);
          const user = await getProfile();
          dispatch(setUser(user?.data?.data as User));
          setFetchingUser(false);
        } catch {
          setFetchingUser(false);
          message.error(ERROR_MESSAGE);
          appLogout();
        }
      };
      fetchUser();
    }
  }, [isAuthenticated, user, appLogout, dispatch]);

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="h-6 w-24 text-center font-semibold">
          {user?.username || 'user'}
        </div>
      )
    },
    {
      key: '4',
      danger: true,
      label: 'Logout',
      icon: <LogoutOutlined />,
      onClick: appLogout
    }
  ];
  return (
    <Header className="flex items-center justify-between gap-8 h-[80px] bg-[#F5F5F5]">
      <div className="flex gap-8">
        <img className="h-[60px] w-fit" src="/images/logo_v3.png" alt="" />
        <div className="text-4xl uppercase leading-[60px]">
          Content Management System
        </div>
      </div>
      <div>
        {isAuthenticated && (
          <Dropdown
            menu={{ items }}
            placement="bottomRight"
            className="header-dropdown"
          >
            <Avatar size={46} icon={<UserOutlined />} />
          </Dropdown>
        )}
      </div>
      <LoadingComponent fullscreen loading={fetchingUser} />
    </Header>
  );
};

export default HeaderComponent;
