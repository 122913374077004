/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Spin, Upload, message } from 'antd';
import heic2any from 'heic2any';
import imageCompression from 'browser-image-compression';
import { DEFAULT_IMAGE } from '../constants/common';
import uploadServices from '../services/uploadServices';

const options = {
  maxSizeMB: 5, // Giới hạn kích thước tối đa của ảnh sau khi nén (5MB ở đây)
  maxWidthOrHeight: 800, // Giới hạn chiều rộng hoặc chiều cao tối đa sau khi nén (800px ở đây)
  useWebWorker: true // Sử dụng Web Worker để nén ảnh (tùy chọn)
};

interface ImageArrayUploadProps {
  maxLength: number;
  fileList: any;
  setFileList: any;
  isDisable: boolean;
}

const ImageArrayUpload = (props: ImageArrayUploadProps) => {
  const { maxLength, fileList, setFileList, isDisable } = props;
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: any) => {
    setPreviewImage(file.url || DEFAULT_IMAGE);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    );
  };

  const isHeicFile = (fileName: any) => {
    const heicExtensions = ['.heic', '.heif'];
    const fileExtension = fileName.toLowerCase().split('.').pop();
    return heicExtensions.includes(`.${fileExtension}`);
  };

  const checkUpload = async (file: any) =>
    file.type.startsWith('image/') || isHeicFile(file.name);

  const beforeUpload = (file: any) => {
    if (!checkUpload(file)) {
      message.error('Only accept image file!');
      return false;
    }
    return true;
  };

  const handleRemove = (file: any) => {
    setFileList((prevList: any) =>
      prevList.filter((item: any) => item.url !== file.url)
    );
  };

  const uploadButton = !isLoading ? (
    !isDisable && (
      <div>
        <PlusOutlined />
        <div
          style={{
            marginTop: 8
          }}
        >
          Upload
        </div>
      </div>
    )
  ) : (
    <Spin />
  );

  const customRequest = async (params: any) => {
    let { file, onSuccess, onError } = params;

    try {
      if (!checkUpload(file)) {
        return;
      }
      setLoading(true);
      const formData = new FormData();
      if (isHeicFile(file.name)) {
        file = await heic2any({ blob: file });
      }
      const compressedImage = await imageCompression(file, options);
      formData.append('file', compressedImage);
      const response = await uploadServices.uploadImage(formData);
      const url = response?.data?.data;
      if (url) {
        onSuccess(url);
        setFileList((prevList: any) => [
          ...prevList,
          {
            status: 'done',
            url
          }
        ]);
        message.success('Image uploaded successfully!');
      } else {
        onError('error');
        message.error('Image upload failed, please try again!');
      }
      setLoading(false);
    } catch (error) {
      onError(error);
      setLoading(false);
      message.error('Image upload failed, please try again!');
    }
  };
  return (
    <>
      <Upload
        customRequest={customRequest}
        beforeUpload={beforeUpload}
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onRemove={handleRemove}
        disabled={isLoading || isDisable}
      >
        {fileList?.length >= maxLength ? null : uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: '100%'
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
};
export default ImageArrayUpload;
