import React from 'react';

interface FilterItemProps {
  filterKey: string;
  filterName: any;
  isActive: boolean;
  handleFilterKey: (key: string) => void;
  hasImage?: boolean;
  imgWidth?: number;
  imgHeight?: number;
  imgUrl?: string;
  avaiableCouter?: number;
}

function FilterItem(props: FilterItemProps) {
  const {
    filterKey,
    filterName,
    isActive,
    handleFilterKey,
    hasImage,
    imgWidth,
    imgHeight,
    imgUrl
  } = props;
  return (
    <div
      className={`bg-[#f6f6f6] border  ${hasImage ? 'rounded-[40px] px-2 h-16 flex items-center justify-center mr-4 mb-4' : 'rounded-lg px-3 inline-block cursor-pointer leading-7  mr-4 mb-4'} ${isActive ? `${hasImage ? 'bg-white text-[#00BA34] border-[#00ba34]' : 'bg-white text-[#00ba34] border-[#00ba34]'}` : ''}`}
      onClick={() => handleFilterKey(filterKey)}
    >
      {hasImage ? (
        <img
          src={imgUrl || ''}
          alt={filterKey}
          width={imgWidth}
          height={imgHeight}
        />
      ) : (
        filterName || 'filter'
      )}
    </div>
  );
}

export default FilterItem;
