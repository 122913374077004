import React from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginComponent from '../pages/Login/LoginComponent';
import GuardRoute from './GuardRoute';
import NewsComponent from '../pages/News/NewsComponent';
import PublicRoute from './PublicRoute';

const AppRoutersComponent = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={<PublicRoute element={<LoginComponent />} />}
      />
      <Route
        path="/news"
        element={<GuardRoute element={<NewsComponent />} />}
      />
      <Route path="*" element={<GuardRoute element={<NewsComponent />} />} />
    </Routes>
  );
};

export default AppRoutersComponent;
