import React, { useEffect, useState } from 'react';
import {
  Button,
  Drawer,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Tabs
} from 'antd';
import {
  CloseOutlined,
  ExclamationCircleFilled,
  EyeOutlined,
  MoreOutlined,
  SaveFilled,
  SendOutlined
} from '@ant-design/icons';
import FormDetailNews from './FormDetailNews';
import { ERROR_MESSAGE, NEWS_STATUS } from '../../constants/common';
import newsServices from '../../services/newsServices';

interface DrawerNewsProps {
  selectedId: string | null;
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  setSelectedId: any;
}

function DrawerNews(props: DrawerNewsProps) {
  const { selectedId, isOpen, setIsOpen, setSelectedId } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [detailData, setDetailData] = useState<any>(null);
  const [statusHandle, setStatusHandle] = useState<NEWS_STATUS | null>(null);
  const [blogForm] = Form.useForm();
  const [preview, setPreview] = useState(false);
  const [reason, setReason] = useState<any>(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const handleAction = (action: NEWS_STATUS) => {
    setStatusHandle(action);
    blogForm.submit();
  };

  const getTitleDrawer = () => {
    if (isEditing) {
      return 'Edit News';
    }
    if (selectedId) {
      return 'Detail News';
    }
    return 'Create News';
  };

  const getExtraDrawer = () => {
    if ((selectedId && !isEditing) || isLoading) return <></>;
    return (
      <div className="drawer-extra flex items-center gap-4">
        <Button
          icon={<CloseOutlined />}
          onClick={() => {
            if (selectedId) {
              setIsEditing(false);
            } else {
              setIsOpen(false);
            }
          }}
        >
          Cancel
        </Button>

        <Button
          type="primary"
          icon={<SaveFilled />}
          className="btn-green"
          onClick={() => handleAction(NEWS_STATUS.DRAFT)}
        >
          Save draft
        </Button>
        <Button
          type="primary"
          icon={<SendOutlined />}
          className="btn-green"
          onClick={() => handleAction(NEWS_STATUS.PENDING)}
        >
          Save and send
        </Button>
      </div>
    );
  };

  const confirmClose = () => {
    if (isEditing || !selectedId) {
      Modal.confirm({
        title: 'Close the news?',
        icon: <ExclamationCircleFilled />,
        content:
          'The entered data will be lost. Are you sure you want to close?',
        onOk() {
          setIsOpen(false);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel() {}
      });
    } else {
      setIsOpen(false);
    }
  };

  const fetchDetail = async () => {
    if (!selectedId) return;
    try {
      setLoading(true);
      const response = await newsServices.getDetailNews(selectedId);
      const mappingData = response?.data?.data;
      blogForm.setFieldsValue({
        title: mappingData?.title,
        slug: mappingData?.slug,
        short_description: mappingData?.short_description
      });
      setDetailData(mappingData);
      setLoading(false);
    } catch {
      setLoading(false);
      message.error(ERROR_MESSAGE);
    }
  };

  const changeStatusBlog = async (id: string, status: NEWS_STATUS) => {
    setLoading(true);
    const body = {
      status,
      reason: null
    };
    if (status === NEWS_STATUS.REJECT) {
      body.reason = reason;
    }
    try {
      await newsServices.editNews(id, body);
      await fetchDetail();
      if (status === NEWS_STATUS.REJECT) {
        setConfirmModal(false);
        setReason(null);
      }
      message.success('Status changed successfully!');
    } catch {
      message.error(ERROR_MESSAGE);
    }
    setLoading(false);
  };

  async function deleteBlog(id: string) {
    setLoading(true);
    try {
      await newsServices.deleteNews(id);
      setStatusHandle(null);
      setDetailData(null);
      setIsOpen(false);
      message.success('Delete successfully!');
    } catch {
      message.error(ERROR_MESSAGE);
    }
    setLoading(false);
  }

  const getDropdownMenu = () => {
    const result = [];
    if (
      detailData?.status === NEWS_STATUS.PENDING ||
      detailData?.status === NEWS_STATUS.HIDE ||
      detailData?.status === NEWS_STATUS.SHOW ||
      detailData?.status === NEWS_STATUS.DRAFT ||
      detailData?.status === NEWS_STATUS.REJECT
    ) {
      result.push({
        label: (
          <div
            className="btn-dropdown"
            role="button"
            tabIndex={0}
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit news
          </div>
        ),
        key: '0',
        disabled: false
      });
    }
    result.push({
      label: (
        <div
          className="btn-dropdown text-red"
          role="presentation"
          onClick={() => {
            if (!selectedId) return;
            deleteBlog(selectedId);
          }}
        >
          Delete news
        </div>
      ),
      key: '2'
    });
    return result;
  };

  const getTabBarExtraContent = () => (
    <div className="wrap-btn flex items-center gap-2">
      {detailData?.status === NEWS_STATUS.PENDING && (
        <>
          <Button className="btn-red" onClick={() => setConfirmModal(true)}>
            Reject
          </Button>
          <Button
            className="btn-green"
            onClick={() => {
              if (!selectedId) return;
              changeStatusBlog(selectedId, NEWS_STATUS.APPROVE);
            }}
          >
            Approve
          </Button>
        </>
      )}
      <EyeOutlined
        onClick={() => setPreview(true)}
        className="cursor-pointer w-10 h-10"
      />
      {getDropdownMenu().length > 0 && (
        <Dropdown
          menu={{
            items: getDropdownMenu()
          }}
          trigger={['click']}
          overlayClassName="sim-dropdown"
        >
          <MoreOutlined className="cursor-pointer" />
        </Dropdown>
      )}
    </div>
  );

  useEffect(() => {
    if (isOpen) {
      if (selectedId) {
        fetchDetail();
      }
    } else {
      setDetailData(null);
      setStatusHandle(null);
      setIsEditing(false);
      setSelectedId(null);
    }
  }, [isOpen, isEditing]);

  return (
    <Drawer
      title={getTitleDrawer()}
      open={isOpen}
      onClose={confirmClose}
      width={1000}
      extra={getExtraDrawer()}
      className="drawer-common"
    >
      <Modal
        centered
        open={confirmModal}
        onCancel={() => {
          setConfirmModal(false);
          setReason(null);
        }}
        onOk={() => {
          if (!selectedId) return;
          changeStatusBlog(selectedId, NEWS_STATUS.REJECT);
        }}
        wrapClassName="modal-activate-sim modal-reject"
        okText="Reject"
        cancelText="Cancel"
        style={{ width: 456 }}
      >
        <div className="modal-content">
          <img src="/images/common/reject-fill.svg" alt="reject" />
          <div className="reject-info">
            <div className="modal-title">Reject the news?</div>
            <div className="modal-description">
              Are you sure you want to reject the news?
            </div>
            <Input.TextArea
              rows={2}
              placeholder="Input reason"
              style={{ marginBottom: '16px' }}
              value={reason || ''}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </div>
      </Modal>
      {isLoading ? (
        <Spin />
      ) : !isEditing && selectedId ? (
        <Tabs tabBarExtraContent={getTabBarExtraContent()}>
          <Tabs.TabPane tab="Detail" key="1">
            {detailData?.reason &&
              detailData?.status === NEWS_STATUS.REJECT && (
                <div
                  className="reason"
                  style={{ marginTop: 0, marginBottom: 12 }}
                >
                  <strong>Reason </strong>
                  {detailData?.reason}
                </div>
              )}
            <FormDetailNews
              detailData={detailData}
              // setDetailData={setDetailData}
              statusHandle={statusHandle}
              selectedId={selectedId}
              blogForm={blogForm}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              preview={preview}
              setPreview={setPreview}
              setLoading={setLoading}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          </Tabs.TabPane>
        </Tabs>
      ) : (
        <FormDetailNews
          detailData={detailData}
          // setDetailData={setDetailData}
          selectedId={selectedId}
          statusHandle={statusHandle}
          blogForm={blogForm}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          preview={preview}
          setPreview={setPreview}
          setLoading={setLoading}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </Drawer>
  );
}

export default DrawerNews;
