import axiosInstance from '../axios/axiosInstance';

const uploadServices = {
  uploadImage(formData: FormData) {
    return axiosInstance.post('/v1/upload-image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
};

export default uploadServices;
