export const ERROR_MESSAGE = 'Something went wrong. Try again later';

export const DEFAULT_PAGINATE_SIZE = 10;
export const DEFAULT_IMAGE =
  'https://statics.simplus.vn/image/f2155c92-08c0-485b-8167-1ef42b6ee04d.jpeg';

export enum NEWS_STATUS {
  //  Draft, Pending, Approve, Reject, Show, Hide
  DRAFT = 'Draft',
  PENDING = 'Pending',
  APPROVE = 'Approve',
  REJECT = 'Reject',
  SHOW = 'Show',
  HIDE = 'Hide',
  ALL = 'all'
}

export const NEWS_STATUS_TO_TEXT = {
  [NEWS_STATUS.ALL]: 'All',
  [NEWS_STATUS.APPROVE]: 'Approved',
  [NEWS_STATUS.DRAFT]: 'Draft',
  [NEWS_STATUS.PENDING]: 'Pending approval',
  [NEWS_STATUS.SHOW]: 'Active',
  [NEWS_STATUS.HIDE]: 'Inactive',
  [NEWS_STATUS.REJECT]: 'Rejected'
};

export const NEWS_STATUS_FILTER = {
  [NEWS_STATUS.ALL]: 'All',
  [NEWS_STATUS.DRAFT]: 'Draft',
  [NEWS_STATUS.PENDING]: 'Pending approval',
  [NEWS_STATUS.SHOW]: 'Active',
  [NEWS_STATUS.HIDE]: 'Inactive',
  [NEWS_STATUS.REJECT]: 'Rejected'
};
