import React, { useEffect, useState } from 'react';
// import useDebounce from '../../hooks/useDebounce';
import {
  DEFAULT_PAGINATE_SIZE,
  NEWS_STATUS_FILTER
} from '../../constants/common';
import { Button, Collapse, DatePicker, Drawer } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import FilterItem from '../../components/FilterItem';

interface FilterNewsProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  queryParams: any;
  setQueryParams: (value: any) => void;
  setPage: (value: number) => void;
}

function FilterNews(props: FilterNewsProps) {
  const { isOpen, setIsOpen, queryParams, setQueryParams, setPage } = props;

  const [filterDate, setFilterDate] = useState<[Dayjs | null, Dayjs | null]>([
    null,
    null
  ]);

  const [defaultExpandedKeys, setDefaultExpandedKeys] = useState<string[]>([
    '1',
    '2',
    '3'
  ]);
  const [statuses, setStatuses] = useState<string>('');

  const handleClearFilter = () => {
    setQueryParams({
      limit: DEFAULT_PAGINATE_SIZE,
      create_started_at: '',
      create_ended_at: '',
      statuses: 'all',
      created_by: []
    });
    setPage(1);
    setIsOpen(false);
  };

  const handleApplyFilter = () => {
    setQueryParams({
      ...queryParams,
      create_started_at: filterDate[0]
        ? filterDate[0].format('YYYY-MM-DD')
        : null,
      create_ended_at: filterDate[1]
        ? filterDate[1].format('YYYY-MM-DD')
        : null,
      statuses
    });
    setPage(1);
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setFilterDate([
        queryParams?.create_started_at
          ? dayjs(queryParams?.create_started_at)
          : null,
        queryParams?.create_ended_at
          ? dayjs(queryParams?.create_ended_at)
          : null
      ]);
      setStatuses(queryParams?.statuses || 'all');
      setDefaultExpandedKeys(['1', '2', '3']);
    }
  }, [isOpen]);

  return (
    <Drawer
      className="drawer-common"
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title="Filter Detail"
      width={800}
      extra={
        <div className="flex items-center gap-3">
          <Button onClick={handleClearFilter}>Clear filter</Button>
          <Button
            onClick={handleApplyFilter}
            className="btn-green"
            type="primary"
          >
            Apply
          </Button>
        </div>
      }
    >
      <Collapse
        activeKey={defaultExpandedKeys}
        onChange={(key) => setDefaultExpandedKeys(key as string[])}
        ghost
      >
        <Collapse.Panel header="By duration" key="1">
          <DatePicker.RangePicker
            value={filterDate}
            size="large"
            style={{ width: '100%' }}
            format="DD/MM/YYYY"
            onChange={(value) =>
              setFilterDate(value as [Dayjs | null, Dayjs | null])
            }
          />
        </Collapse.Panel>

        <Collapse.Panel header="By status" key="3">
          <div>
            {Object.entries(NEWS_STATUS_FILTER).map(([key, value], index) => {
              return (
                <FilterItem
                  handleFilterKey={() => {
                    setStatuses(key);
                  }}
                  isActive={statuses === key}
                  key={index}
                  filterName={value}
                  filterKey={key}
                />
              );
            })}
          </div>
        </Collapse.Panel>
      </Collapse>
    </Drawer>
  );
}

export default FilterNews;
