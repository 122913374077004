import { useEffect, useState } from 'react';

function useDebounce(keyword: string, delay: number) {
  const [result, setResult] = useState(keyword);

  useEffect(() => {
    const handler = setTimeout(() => {
      setResult(keyword);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [keyword]);

  return result;
}

export default useDebounce;
