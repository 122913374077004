import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import uploadServices from '../services/uploadServices';

interface CKEditorCustomProps {
  editorContent: string;
  setEditorContent: any;
  isReadOnly: boolean;
}

function CkEditorCustom(props: CKEditorCustomProps) {
  const { editorContent, setEditorContent, isReadOnly } = props;
  async function uploadAsync(loader: any) {
    try {
      const file = await loader.file;
      const formData = new FormData();
      formData.append('file', file);
      const response = await uploadServices.uploadImage(formData);
      const url = response?.data?.data;

      return {
        default: url
      };
    } catch (error: any) {
      throw new Error(error);
    }
  }

  function uploadAdapter(loader: any) {
    return {
      upload: () =>
        new Promise((resolve, reject) => {
          uploadAsync(loader).then(resolve).catch(reject);
        }),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      abort: () => {}
    };
  }

  function uploadPlugin(editor: any) {
    // eslint-disable-next-line no-param-reassign
    editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) =>
      uploadAdapter(loader);
  }

  return (
    <CKEditor
      config={{
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [uploadPlugin]
      }}
      editor={ClassicEditor}
      onReady={(editor) => {
        if (!isReadOnly) {
          editor.disableReadOnlyMode('unique-lock-id');
        } else {
          editor.enableReadOnlyMode('unique-lock-id');
        }
      }}
      onChange={(event, editorInstance) => {
        setEditorContent(editorInstance.getData());
      }}
      data={editorContent}
    />
  );
}

export default CkEditorCustom;
